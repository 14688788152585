import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Filter } from '@mui/icons-material';
import { SOCKET } from '../../utils/Constants';
import io from 'socket.io-client';
import { URL } from '../../utils/Constants';
import { postRequest } from '../../utils/axiosClient';
import { getKiosk } from '../../utils/config';
import { enqueueSnackbar } from 'notistack';

const Filters = [
  'Show Top 10',
  'Highlight',
  "Today's View",
  'Monthly View',
  'Play Video',
  'Stop Video',
  'All Time',
];

const games = {
  '643d08855731092343955149': 'Bowling',
  '643d0c37573109234395514b': 'Racing Simulator',
  '65a620fbedb75f193306dc32': 'Racing Simulator',
};

export const sendRequest = async (filter, scoreType) => {
  let str = URL;
  let data = {};
  if (filter.currentFilter === Filters[2]) {
    str += '/game/fetchByRank';
    data.dateTime = new Date();
  } else if (filter.currentFilter === Filters[0]) {
    //top 10 view
  } else if (filter.currentFilter === Filter[1]) {
    // highlight player
  } else if (filter.currentFilter === Filters[6]) {
    //all time view
    str += '/game/allTimeRank';
  } else if (filter.currentFilter === Filters[3]) {
    //montly view
    str += '/game/fetchByMonth';
    data.dateTime = new Date();
  }
  data.scoreType = scoreType;
  data.gameId = filter.gameId;
  let response = await axios.post(str, data);
  return response;
};

export const EmpUpNav = ({ branchId }) => {
  let gameId = localStorage.getItem('gameId');
  let [gameName, setGameName] = useState('');

  const [pageState, setPageState] = useState({ state: 'unknown', message: '' });
  useEffect(() => {
    setPageState({ message: '', state: 'loading' });
    const onAcceptGetKiosk = (response) => {
      console.log(response);
      if (response.status === 200) {
        const index = response.data.message.findIndex(
          (el) => el.gameId === gameId
        );
        console.log(index);
        if (index >= 0) {
          setGameName(response.data.message[index].name);
          setPageState({ message: '', state: 'done' });
        }
      } else {
        console.log(response);
        enqueueSnackbar('Some error occurred while getting games');
      }
    };
    const onRejectGetKiosk = (error) => {
      console.log(error);
      enqueueSnackbar('Some error occurred while getting games');
    };
    getKiosk('/kiosks', onAcceptGetKiosk, onRejectGetKiosk);
  }, []);
  const logout = () => {
    localStorage.removeItem('empToken');
    window.location = '/emplogin?branchid=' + branchId;
  };

  if (pageState.state === 'error') return <span>_</span>;
  if (pageState.state === 'loading') return <span>loading...</span>;

  return (
    <EmpNavStyle>
      <div className="UpBar">
        <div className="UpBarLeft">
          <div
            className="UpbarIcon"
            onClick={(e) =>
              (window.location = '/empdashboard?branchid=' + branchId)
            }
          >
            <img src="home2.png" />
          </div>
          <span>{gameName || 'Bowling'}</span>
        </div>
        <div className="UpBarRight">
          <span className="UpBarLogout" onClick={logout}>
            Logout
          </span>
          <div onClick={logout} className="UpbarRightIcon">
            <img src="logout.png" />
          </div>
        </div>
      </div>
    </EmpNavStyle>
  );
};

const EmpNav = ({ branchId, setLeaderboardView, filters, setFilters }) => {
  const [kiosk, setKiosk] = useState();
  const [loading, setLoading] = useState(false);
  const getCurrentFilterIndex = () => {
    if (!filters.currentFilter) return -1;
    let i = Filters.findIndex((el) => el === filters.currentFilter);
    console.log(i);
    return i;
  };
  const [currentIndex, setCurrentIndex] = useState(getCurrentFilterIndex());
  const [Socket, setSocket] = useState(null);
  const [currentMultiSelectIndex, setCurrentMultiSelectIndex] = useState(-1);

  useEffect(() => {
    const socket = io(SOCKET);
    socket.connect();
    socket.on('connect', () => console.log('connected'));
    setSocket(socket);
    localStorage.removeItem('activePlayerEvent');
    const onAcceptGetKiosk = (response) => {
      if (response.status === 200) {
        const index = response.data.message.findIndex(
          (el) => el.gameId === localStorage.getItem('gameId')
        );
        console.log('index', index);
        if (index >= 0) {
          setKiosk(response.data.message[index]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const onRejectGetKiosk = (e) => {
      console.log(e);
      setLoading(false);
    };
    setLoading(true);
    getKiosk('/kiosks', onAcceptGetKiosk, onRejectGetKiosk);
  }, []);

  useEffect(() => {
    console.log(kiosk);
  }, [kiosk]);

  const getHighlightUser = () => {
    let userId = localStorage.getItem('activePlayerId');
    let gameId = localStorage.getItem('gameId');
    let eventId = localStorage.getItem('activePlayerEvent');
    console.log(eventId);
    if (eventId) {
      setFilters({
        currentFilter: filters.currentFilter
          ? filters.currentFilter
          : "Today 's View",
        userId: userId,
        scoreType: kiosk.scoreType,
        gameId: gameId,
        activePlayerEventId: eventId,
      });
      Socket.emit('eswitch', {
        msg: filters.currentFilter ? filters.currentFilter : "Today 's View",
        gameId: gameId || null,
        activePlayerEventId: eventId,
        scoreType: kiosk.scoreType || 'number',
      });
      return;
    }
    axios
      .post(URL + '/game/getUserEvent', {
        playerId: userId,
        gameId: gameId,
        scoreType: kiosk.scoreType
      })
      .then((response) => {
        if (response.status === 200) {
          setFilters({
            currentFilter: filters.currentFilter
              ? filters.currentFilter
              : "Today 's View",
            userId: userId,
            gameId: gameId,
            scoreType: kiosk.scoreType,
            activePlayerEventId: response.data[0]._id,
          });
          Socket.emit('eswitch', {
            msg: filters.currentFilter
              ? filters.currentFilter
              : "Today 's View",
            gameId: gameId || null,
            scoreType: kiosk.scoreType,
            activePlayerEventId: response.data[0]._id,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let gameId = localStorage.getItem('gameId');
  let gameName = games[gameId];
  if (loading) return <span>Loading...</span>;
  return (
    <EmpNavStyle style={{ height: '100%' }}>
      <div className="EmpNav">
        <div className="BottomNav">
          {Filters.map((filter, i) => {
            if (
              filter === 'Show Top 10' ||
              filter === 'Highlight' ||
              filter === 'All Time'
            ) {
              return;
            }
            return (
              <span
                key={i}
                onClick={(e) => {
                  if (i == 4) {
                    console.log('clicked on play video');
                    Socket.emit('eswitch', {
                      msg: 'playvideo',
                      gameId: localStorage.getItem('gameId') || null,
                      scoreType: kiosk.scoreType,
                    });
                    return;
                  }
                  if (i == 5) {
                    console.log('clicked on play video');
                    Socket.emit('eswitch', {
                      msg: 'Intro Video',
                      gameId: localStorage.getItem('gameId') || null,
                      scoreType: kiosk.scoreType,
                    });
                    return;
                  }
                  if (currentIndex === i) {
                    setCurrentIndex(-1);
                  } else {
                    setCurrentIndex(i);
                  }
                  if (i == 2 || i == 3) {
                    setCurrentMultiSelectIndex(i);
                  }
                  if (i == 0) {
                    setFilters({
                      currentFilter:
                        filters.currentFilter &&
                        filters.currentFilter.length > 1
                          ? filters.currentFilter
                          : "Today's View",
                      gameId: gameId,
                      scoreType: kiosk.scoreType,
                    });
                    Socket.emit('eswitch', {
                      msg:
                        filters.currentFilter &&
                        filters.currentFilter.length > 1
                          ? filters.currentFilter
                          : "Today's View",
                      gameId: localStorage.getItem('gameId') || null,
                      scoreType: kiosk.scoreType,
                    });
                  } else if (i == 1) {
                    if (currentIndex === 1) {
                      setFilters({
                        currentFilter:
                          filters.currentFilter &&
                          filters.currentFilter.length > 1
                            ? filters.currentFilter
                            : "Today's View",
                        gameId: gameId,
                        scoreType: kiosk.scoreType,
                      });
                      Socket.emit('eswitch', {
                        msg:
                          filters.currentFilter &&
                          filters.currentFilter.length > 1
                            ? filters.currentFilter
                            : "Today's View",
                        gameId: localStorage.getItem('gameId') || null,
                        scoreType: kiosk.scoreType,
                      });
                    } else {
                      getHighlightUser();
                    }
                  } else {
                    setFilters({
                      currentFilter: Filters[i],
                      gameId: gameId,
                      scoreType: kiosk.scoreType,
                    });
                    if (!Socket) {
                      console.log('null');
                    }
                    Socket.emit('eswitch', {
                      msg: Filters[i],
                      gameId: localStorage.getItem('gameId') || null,
                      scoreType: kiosk.scoreType,
                    });
                  }
                }}
                className={
                  currentIndex === i || currentMultiSelectIndex === i
                    ? 'active'
                    : null
                }
              >
                {filter}
              </span>
            );
          })}
        </div>
      </div>
    </EmpNavStyle>
  );
};

export default EmpNav;

const EmpNavStyle = styled.div`
  .UpBar {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    color: white;
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .UpBarLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
  }

  .UpbarIcon {
    cursor: pointer;
    width: 25px;
  }

  .UpbarRightIcon {
    width: 20px;
    height: 15px;
    cursor: pointer;
    margin-bottom: 0.2rem;
  }

  .UpbarRightIcon img {
    width: 100%;
    align-self: center;
    self-align: center;
  }

  .UpBarRight {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .UpBarLogout {
    font-size: 1.2rem;
    align-self: center;
  }

  .EmpNav {
    background-color: rgba(27, 27, 27, 1);
    display: flex;
    gap: 0.5rem;
    color: white;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .TopNav {
    background: rgba(249, 173, 73, 1);
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: row;
    height: 10%;
    color: black;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  }

  .active {
    background: rgba(249, 173, 73, 1);
  }

  .TopNav div {
    width: 25px;
    height: 15px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .BottomNav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem 1rem;
    height: 80%;
    box-sizing: border-box;
  }

  .BottomNav span {
    border: 1px solid white;
    max-width: 150px;
    padding: 0.2rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    text-align: center;
    border-radius: 21px;
  }

  @media screen and (max-width: 600px) {
    .BottomNav span {
      font-size: 0.8rem;
      width: 80%;
      justify-self: center;
    }
    .BottomNav {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem 0rem;
      padding: 0.6rem;
      justify-content: center;
      display: grid;
    }

    .UpBarLeft {
      font-size: 17px;
    }

    .UpBarLogout {
      font-size: 17px;
    }

    .UpbarRightIcon {
      width: 16px;
      height: 18px;
    }

    .UpbarRightIcon img {
      object-fit: contain;
    }

    .UpbarIcon {
      width: 20px;
      height: 18px;
    }
  }
`;
