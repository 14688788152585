import styled from "@emotion/styled"
import { useState, useEffect } from "react"
import { OTP_STATUS } from "../../utils/Constants";
import { useRef } from "react";
import { postRequest } from "../../utils/axiosClient";

const constantAvatars = ['av1.png', 'av2.png', 'av3.png', 'av4.png', 'av5.png', 'custom.svg'];


const Avatar = ({ hostInfo, setHostInfo, pageIndex, otpVerified }) => {
  const imageRef = useRef(null);
  const [key, setKey] = useState(0);
  const [avatars, setAvatars] = useState(constantAvatars);


 
  const sendReq = async (e) => {
    try {
      console.log(e.target.files[0]);
      if (!e.target.files  || e.target.files.length <= 0) return; 
      let response = await postRequest({
        endpoint: '/aws/uploadSingleFile', 
        data: {file: e.target.files[0]}, 
        dispatch: null, 
        controller: new AbortController(), 
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status == 200) {
        setHostInfo({ ...hostInfo, [pageIndex]: { ...hostInfo[pageIndex], 'avatar': response.msg } }); 
        imageRef.current.parentElement.children[0].src = response.msg;
      } else {
        console.log(response);
        return null; 
      }
    } catch (e) {
      console.log(e);
      return null; 
    }
  }

  const clickHandler = (e, i) => {
    if (otpVerified === OTP_STATUS.UNVERIFIED || otpVerified === OTP_STATUS.VERIFIED) {
      if (i === constantAvatars.length - 1) {
        setKey(i);
      } else {
        setKey(i);
        setHostInfo({ ...hostInfo, [pageIndex]: { ...hostInfo[pageIndex], 'avatar': avatars[key] } })
      }
      
    }
  }

  useEffect(() => {
  }, [avatars])

  return (
    <AvStyle>
      <div className='avatars'>
        {avatars.map((av, i) => {
          return (
            <div key={i}  onClick={(e) => clickHandler(e, i)} className={key === i ? 'clicked AvImage' : 'AvImage'} >
              <img src={av} className='av1.png' onClick={(e) => av === 'custom.svg' ? imageRef.current.click() : null  } />
              { av === 'custom.svg' ? <input ref={imageRef} type='file' accept="capture=camera,image/*" id='img' hidden={true}  onChange={(e) => sendReq(e, i)}/> : null }
            </div>
          )
        })}
      </div>
    </AvStyle>
  )
}

export default Avatar;


const AvStyle = styled.div`

  .avatars {
    display: grid;
    grid-template-columns: repeat(6, 1fr); 
    gap: 0.5rem;
    padding: 0.3rem;
  }


  .clicked {
    box-shadow: 2px 2px 12px 2px rgba(9, 123, 62, 1), -2px -2px 12px 2px rgba(9, 123, 62, 1);
  }

  .AvImage {
    border-radius: 50%; 
    width: 40px; 
    height: 35px;
  }


  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
` 