import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from 'styled-components';
import { Button } from '@mui/material'
import { Context } from '../AuthState';
import { useContext, useState } from "react";
import { TableBody,  TableContainer, Table, TableRow, TableHead } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import { useEffect } from "react";
import NavBar from '../../../customComponent/Navbar';
import { postRequest  } from "../../../../utils/axiosClient";
import { PAGE_STATE } from "../../../../utils/Constants";

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

//fetch host information from some connected state
const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.9)", // (default alpha is 0.38)
      backgroundColor: 'rgba(238, 238, 238, 1)'
    }
  }
})(TextField);




const MultiPlayerInfo = ({ branchId }) => {
  const [players, setPlayers] = useState([]);
  const [hostInfo, setHostInfo] = useState({ hostName: '', hostNumber: '' });
  const [pageState, setPageState] = useState({ msg: 'Loading', state: PAGE_STATE.NOTLOADED });

  useEffect(() => {
    let playerIds = localStorage.getItem('playerIds');
    let host = localStorage.getItem('hostInfo'); 
    host = JSON.parse(host);
    playerIds = JSON.parse(playerIds);
    
    if (!hostInfo || hostInfo.length == 0) {
      setHostInfo({ hostName: '', hostNumber: '' }); 
    } else {
      setHostInfo({ hostName: host.hostName, hostNumber: host.hostNumber || host.hostPhoneNumber }); 
    }


    if (!playerIds || playerIds.length <= 1) {
    } else {
      postRequest({
        endpoint: '/auth/playerinformation', 
        dispatch: null, 
        controller: new AbortController(),
        data: { playerIds: [...playerIds] }, 
        headers: {}
      })
      .then(response => {
        if (response.status === 200) {
          setPlayers(response.msg);
          setPageState({ msg: 'Loaded', state: PAGE_STATE.VERIFIED }); 
        } else {
          setPageState({ msg: 'Error', state: PAGE_STATE.UNVERIFIED });
        }
      })
      .catch(err => {
        console.log(err);
        setPageState({ msg: 'Error', state: PAGE_STATE.UNVERIFIED });
      })
    }
  }, [])  


  if (pageState.state != PAGE_STATE.VERIFIED) {
    return (
      <h1>{pageState.msg}</h1>
    )
  } 


  return (
    <>
      <PlayerCodeStyle>
        <div className='PlayerCode'>

          <div className='HostInfo'>
            <DarkerDisabledTextField 
              disabled 
              variant='filled'
              value={hostInfo.hostName}
              InputProps={{ style: { height: '40px',  paddingBottom: '0.5rem',  borderRadius: '8px' }, disableUnderline: true}}
              name='name'
              fullWidth
            />        
            <DarkerDisabledTextField
              disabled 
              variant='filled'
              value={hostInfo.hostNumber}
              InputProps={{ style: { height: '40px',  paddingBottom: '0.7rem', borderRadius: '8px' }, disableUnderline: true }}
              name='number'
              fullWidth
            />        
          </div>
      <div className='PlayersInfo'>
        <Button type='text' disableRipple style={{ backgroundColor: 'transparent' }}>You are Hosting Following Players</Button>
        <div className='Players'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: 'rgba(249, 173, 73, 0.75)' }}>
                  <TableCell size='small' align='left'>No</TableCell>
                  <TableCell align='left' size='small' sx={{ backgroundColor: 'black' }}></TableCell>
                  <TableCell align='left' size='small'>Name</TableCell>
                  <TableCell align='right' size='small'>Code</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {players.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell size='small' align='left' >P{i + 1}</TableCell>
                      <TableCell align='left' size='small' ><img src={row.avatar}/></TableCell>
                      <TableCell align='left' size='small'>{row.name}</TableCell>
                      <TableCell align='right' size='small'>{row.code}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

        </div>
      </PlayerCodeStyle>
    </>
  )
}


export default MultiPlayerInfo; 

const PlayerCodeStyle = styled.div`
  .PlayerCode { 
    display: grid; 
    grid-template-columns: repeat(8, 1fr); 
    text-align: center; 
  }


  .HostInfo {
    grid-column: 1 / -1;
    display: flex; 
    flex-direction: column; 
    gap: 0.4rem;
  }


  .PlayersInfo {
    grid-column: 1 / -1;
    text-align: left;
    padding-top: 0.7rem;
    z-index: -1;
  }

  .PlayersInfo Button {
    text-transform: none;
    letter-spacing: 1.5px;
    color: rgba(0, 0, 0, 1); 

  }

  img {
    border-radius: 50%; 
    width: 45px; 

  }

  .Players {
    padding-top: 0.2rem;
    font-weight: 400; 
    color: rgba(0, 0, 0, 0.1) !important;
  }


  @media screen and (max-width: 450px) {
    img {
      width: 35px;
    }
  }


`