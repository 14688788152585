import MultiPlayerInfo  from './MultiPlayerInfo'; 
import SinglePlayerInfo from './SinglePlayerInfo';

const PlayerInfo = ({ branchId, leaderboardView, playerLeaderBoardView }) => {
  let playerIds = localStorage.getItem('playerIds'); 
  playerIds = JSON.parse(playerIds);
  if (playerIds.length <= 1 && !leaderboardView && !playerLeaderBoardView) {
    return (
      <SinglePlayerInfo branchId={branchId} />
    )
  } else if (playerIds.length > 1 && !leaderboardView && !playerLeaderBoardView) {
    return (
      <MultiPlayerInfo branchId={branchId} />
    )
  }
}; 

export default PlayerInfo; 


