import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { URL } from '../../utils/Constants'
import axios from 'axios';;


let icons = ['contact-icon.svg', 'all-icon.svg', 'race-icon.svg', 'bowl-icon.svg'];

const Navbar = ({ style = false, playerId, setPlayerId, playerLeaderboardView, setLeaderboardView, setPlayerLeaderboardView, setFilters, filters, leaderboardView }) => {
  const [activePlayerIndex, setActivePlayerIndex] = useState(-1);
  const [active, setActive] = useState(0);
  const [filter, setActiveFilter] = useState(0);
  const [activePlayerId, setActivePlayerId] = useState(0);
  const [activePlayerEventId, setActivePlayerEventId] = useState(null);
  const players = JSON.parse(localStorage.getItem('playerIds'));

  const getGameId = () => {
    let gameId;
    if (active === 2) {
      gameId = '643d0c37573109234395514b';
    } else if (active == 3) {
      gameId = '643d08855731092343955149';
    }
    return gameId;
  }

  const addFilter = async () => {
    let gameId = getGameId();
    let defFilter = { currentFilter: 'Today\'s View', gameId };

    if (players.length === 1) {
      let id = await getPlayerInfo(players[0]);
      defFilter.activePlayerEventId = id;
    }

    if (filter === 0) {
      defFilter.currentFilter = 'Today\'s View'
    } else if (filter === 1) {
      defFilter.currentFilter = 'Monthly View';
    }
    setFilters(defFilter);
  }

  const getPlayerInfo = async (id) => {
    if ((active === 1 || active === 0)) return;
    try {
      let response = await axios.post(URL + '/game/getUserEvent',
        {
          playerId: id,
          gameId: getGameId()
        })
      if (response.status === 200) {
        if (players.length === 1) {
        } else {
          setFilters({ ...filters, activePlayerEventId: response.data[0]._id });
        }
        localStorage.setItem('activePlayerId', id);
        return response.data[0]._id;
      } else {
        console.log('error')
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    console.log(filters)
  }, [filters])




  useEffect(() => {
    if (!activePlayerId) return;
    if (players.length === 1) return;
    getPlayerInfo(activePlayerId);
    setPlayerId(activePlayerId);
  }, [activePlayerId])

  useEffect(() => {
    addFilter();
  }, [active, filter])


  useEffect(() => {
    if (active == 1) {
      setPlayerLeaderboardView(true);
    }
  }, [activePlayerId]);


  const handleClick = (e, index) => {
    if (activePlayerIndex === index) {
    } else {
      setActivePlayerIndex(index);
      setActivePlayerId(e.target.id);
    }
    if (active === 1) {
      setPlayerLeaderboardView(true);
    }
  }

  const handleParentNavClick = (i) => {
    if (i === active) return;
    setActive(i);
    if (i == 1) {
      setPlayerLeaderboardView(true);
    } else if (i == 0) {
      setLeaderboardView(false);
      setPlayerLeaderboardView(false);
    } else {
      setLeaderboardView(true);
      if (playerLeaderboardView) {
        setPlayerLeaderboardView(false);
      }
      setActivePlayerIndex(-1);
      setActivePlayerId(null);
    }
  }

  return (
    <NavStyle>
      <div className='parentNav' style={style ? style : null}>
        <div className='nav'>
          {icons.map((icon, i) => {
            return (
              <>
                <div key={i} onClick={() => handleParentNavClick(i)} className='navImage'>
                  <img src={icon} className={active === i ? 'black' : 'navImage'} />
                </div>
                {i > 1 ? <div key={i + 1} className={i === active && i != 0 ? 'filters' : 'hidden'}>
                  <div onClick={() => {
                    setActiveFilter(0);
                  }} className={filter === 0 ? 'activeFilter' : 'filter1'}>T</div>
                  <div onClick={() => {
                    setActiveFilter(1);
                  }} className={filter === 1 ? 'activeFilter' : 'filter2'}>M</div>
                </div> : null}
              </>
            )
          })}
        </div>
        <div className={players && players.length > 1 ? 'playerNav' : 'hidden'}>
          {players && players.length > 1 && players.map((player, i) => {
            return (
              <div id={player} onClick={(e) => handleClick(e, i)} key={i} className={i == activePlayerIndex ? 'player active' : 'player'}>P{(i + 1)}</div>
            )
          })}
        </div>
      </div>

    </NavStyle>
  )
}

export default Navbar;

const NavStyle = styled.div`
  .nav {
    display: flex; 
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    padding: 0.5rem;
    background: rgba(248, 152, 28, 0.8);
    height: 100%;
  }

  .black {
    filter: invert(1);
  }

  .playerNav {
    text-align: center;
    height: 100%;
    background: black;
    padding: 0.5rem;
    width: 21px
    font-size: 7px;
    font-weight: 600;
  }

  .nav div {
    cursor: pointer;
  }

  .navImage {
    width: 21px;
    height: 21px;
    margin: 0;
  }

  .navImage img {
    width: 100%; 
    height: 100%;
    object-fit: fill; 
  }

  .player {
    padding: 0.2rem;
    border: 1px solid white;
    color: white !important;
    border-radius: 50%;
    margin-top: 0.5rem;
    font-size: 0.7rem;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .active {
    background: rgba(248, 152, 28, 0.8);
  }

  .parentNav {
    height: 100vh;
    display: flex; 
    flex-direction: row;
    z-index: 2;
  }


  .filters {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justiy-content: center;
    gap: 0.3rem;
  }

  .filters div {
    border: 1px solid white;
    border-radius: 50%;
    padding: 0.1rem 0.4rem;
    color: white;
  }

  .activeFilter {
    background-color: rgba(66, 93, 102, 1);
  }

  .hidden {
    display: none;
  }
`