import axios from 'axios';
export const Config = {
  KIOSK_URL: 'https://lmsbackend.projectteho.com/api',
};


export const getKiosk = async (path, onAccept, onReject) => {
  try {
    let response = await axios.get(Config.KIOSK_URL + path); 
    onAccept(response);
  } catch (e) {
    onReject(e);
  }
};
