import { Routes, Route } from 'react-router-dom';
import Register from './components/pages/user/register/Register';
import MultiPlayerInfo from './components/pages/user/playerInfo/MultiPlayerInfo';
import LeaderBoard from './components/pages/user/ranks/PlayerLeaderBoard';
import SinglePlayerInfo from './components/pages/user/playerInfo/SinglePlayerInfo';
import EmpLogin from './components/pages/employee/register/login';
import './index.css';
import { Objects, Context } from './components/pages/user/AuthState';
import { EmpContext } from './components/pages/employee/empInfo';
import { useState, useEffect } from 'react';
import LeaderBoardAll from './components/pages/user/ranks/LeaderBoardAll';
import Navbar from './components/customComponent/Navbar';
import Topbar from './components/customComponent/Topbar';
import EmpNav from './components/customComponent/EmpNav';
import PlayerRegister from './components/pages/employee/player/PlayerRegister';
import EmpPlayerInfo from './components/pages/employee/player/EmpPlayerInfo';
import PlayerInfo from '../src/components/pages/user/playerInfo/PlayerInfo';
import { PAGE_STATE } from './utils/Constants';
import { PageState } from '../src/pageState';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { EmpUpNav } from './components/customComponent/EmpNav';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

const CheckComponent = ({ el, state, emp, user }) => {
  const [playerId, setPlayerId] = useState(null);
  const isKeyboardOpen = useDetectKeyboardOpen();

  if (emp) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100vw',
          justifyContent: 'space-between',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <div
          style={{
            height: 'fit-content',
            zIndex: '2',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Topbar />
          <EmpUpNav branchId={state.branchId} />
        </div>
        <div
          style={{
            height: '65vh',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {el}
          <LeaderBoardAll
            filters={state.filters}
            setFilters={state.setFilters}
            branchId={state.branchId}
            leaderboardView={state.leaderboardView}
            setLeaderboardView={state.setLeaderboardView}
          />
        </div>
        <div
          style={
            !isKeyboardOpen
              ? {
                  height: '20vh',
                  backgroundColor: 'grey',
                  position: 'fixed',
                  bottom: '0',
                  width: '100%',
                }
              : { height: '20vh' }
          }
        >
          <EmpNav
            filters={state.filters}
            setFilters={state.setFilters}
            setLeaderboardView={state.setLeaderboardView}
            branchId={state.branchId}
          />
        </div>
      </div>
    );
  } else if (user) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        <Topbar />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <div>
            <Navbar
              style={{ width: '100%' }}
              playerId={playerId}
              setPlayerId={setPlayerId}
              playerLeaderboardView={state.playerLeaderBoardView}
              filters={state.filters}
              setFilters={state.setFilters}
              setLeaderboardView={state.setLeaderboardView}
              leaderboardView={state.leaderboardView}
              branchId={state.branchId}
              setPlayerLeaderboardView={state.setPlayerLeaderboardView}
            />
          </div>
          <div
            style={{
              flexGrow: '1',
              overflowY: 'hidden',
              overflowX: 'hidden',
              maxWidth: '100vw',
            }}
          >
            {el}
            <LeaderBoard
              playerId={playerId}
              setPlayerId={setPlayerId}
              branchId={state.branchId}
              playerLeaderBoardView={state.playerLeaderBoardView}
              setPlayerLeaderView={state.setPlayerLeaderboardView}
            />
            <LeaderBoardAll
              filters={state.filters}
              setFilters={state.setFilters}
              branchId={state.branchId}
              leaderboardView={state.leaderboardView}
              setLeaderboardView={state.setPlayerLeaderboardView}
            />
          </div>
        </div>
      </div>
    );
  } else if (state.mainPage) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Topbar filters={state.filters} />
        {el}
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflowX: 'hidden',
        }}
      >
        <div>
          <Topbar />
        </div>
        <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
          {el}
          <div style={{ width: '100vw', height: '100px' }}></div>
        </div>
      </div>
    );
  }
};

const MakeContext = ({ el, state, emp = false, user = false }) => {
  return (
    <PageState.pageState.Provider
      value={{ pageState: state.pageState, setPageState: state.setPageState }}
    >
      <Context.HostInfo.Provider
        value={{ hostInfo: state.hostInfo, setHostInfo: state.setHostInfo }}
      >
        <Context.Range.Provider
          value={{
            rangeIndex: state.rangeIndex,
            setRangeIndex: state.setRangeIndex,
          }}
        >
          <Context.playerIds.Provider
            value={{
              playerIds: state.playerIds,
              setPlayerIds: state.setPlayerIds,
              branchId: state.branchId,
            }}
          >
            {CheckComponent({ el, state, emp, user })}
          </Context.playerIds.Provider>
        </Context.Range.Provider>
      </Context.HostInfo.Provider>
    </PageState.pageState.Provider>
  );
};

const MakeContextEmp = ({ el, state }) => {
  return (
    <PageState.pageState.Provider
      value={{ pageState: state.pageState, setPageState: state.setPageState }}
    >
      <EmpContext.EmpInfo.Provider
        value={{ empInfo: state.empInfo, setEmpInfo: state.setEmpInfo }}
      >
        <Context.Range.Provider
          value={{
            rangeIndex: state.rangeIndex,
            setRangeIndex: state.setRangeIndex,
            branchId: state.branchId,
          }}
        >
          {el}
        </Context.Range.Provider>
      </EmpContext.EmpInfo.Provider>
    </PageState.pageState.Provider>
  );
};

//disble cursor in code box

function App() {
  const [hostInfo, setHostInfo] = useState({
    hostName: '',
    hostPhoneNumber: '',
  });
  const [rangeIndex, setRangeIndex] = useState(1);
  const [playerIds, setPlayerIds] = useState([]);
  const [empInfo, setEmpInfo] = useState({
    empNumber: '',
    loginFor: 'Bowling',
  });
  const [pageState, setPageState] = useState({
    state: PAGE_STATE.NOTLOADED,
    msg: 'Loading...',
    others: {},
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [leaderboardView, setLeaderboardView] = useState(false);
  const [playerLeaderBoardView, setPlayerLeaderboardView] = useState(false);
  let branchId = searchParam.get('branchid') || searchParam.get('branchId');
  const [filters, setFilters] = useState({
    currentFilter: '',
    user_id: '',
    player_id: '',
  });

  useEffect(() => {
    if (pageState && pageState.others?.msg) {
      if (pageState.others?.error) {
        enqueueSnackbar(pageState.others.msg, {
          autoHideDuration: 3000,
          variant: 'error',
        });
      } else if (!pageState.others?.error) {
        enqueueSnackbar(pageState.others.msg, {
          autoHideDuration: 3000,
          variant: 'success',
        });
      } else {
        enqueueSnackbar(pageState.others.msg, {
          autoHideDuration: 3000,
          variant: 'default',
        });
      }
    }
  }, [pageState.others]);

  // useEffect(() => {
  //   console.log(hostInfo);
  // }, [hostInfo])

  // useEffect(() => {
  //   console.log(empInfo);
  // }, [empInfo]);

  useEffect(() => {
    if (leaderboardView) {
      setPlayerLeaderboardView(false);
    }
  }, [leaderboardView]);

  if (!branchId) {
    return <h1>No Branch Id Found</h1>;
  }

  return (
    <Routes>
      <Route
        path="/register"
        element={
          <MakeContext
            el={<Register branchId={branchId} />}
            state={{
              setFilters,
              filters,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />
      <Route
        path="/leaderboard"
        element={
          <MakeContext
            el={<LeaderBoard branchId={branchId} />}
            state={{
              setFilters,
              filters,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />
      <Route
        path="/leaderboardall"
        element={
          <MakeContext
            el={
              <LeaderBoardAll
                branchId={branchId}
                mainPage={true}
                setFilters={setFilters}
                filters={filters}
              />
            }
            state={{
              mainPage: true,
              setFilters,
              filters,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />
      <Route
        path="/playerinfo"
        element={
          <MakeContext
            el={
              <PlayerInfo
                branchId={branchId}
                playerLeaderBoardView={playerLeaderBoardView}
                leaderboardView={leaderboardView}
              />
            }
            user={true}
            state={{
              playerLeaderBoardView,
              setPlayerLeaderboardView,
              leaderboardView,
              setLeaderboardView,
              setFilters,
              filters,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />

      <Route
        path="/emplogin"
        element={
          <MakeContextEmp
            el={<EmpLogin branchId={branchId} />}
            state={{
              setFilters,
              filters,
              branchId,
              pageState,
              setPageState,
              empInfo,
              setEmpInfo,
            }}
          />
        }
      />
      <Route
        path="/empdashboard"
        element={
          <MakeContext
            el={
              <PlayerRegister
                branchId={branchId}
                leaderboardView={leaderboardView}
                setLeaderboardView={setLeaderboardView}
              />
            }
            emp={true}
            state={{
              setFilters,
              filters,
              leaderboardView,
              setLeaderboardView,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />
      <Route
        path="/empplayerinfo"
        element={
          <MakeContext
            el={
              <EmpPlayerInfo
                branchId={branchId}
                leaderboardView={leaderboardView}
                filters={filters}
                setFilters={setFilters}
                setLeaderboardView={setLeaderboardView}
              />
            }
            emp={true}
            state={{
              setFilters,
              filters,
              leaderboardView,
              setLeaderboardView,
              branchId,
              pageState,
              setPageState,
              hostInfo,
              setHostInfo,
              rangeIndex,
              setRangeIndex,
              playerIds,
              setPlayerIds,
            }}
          />
        }
      />
    </Routes>
  );
}

export default App;
