import styled from "styled-components";
import { TextField, FormControl, MenuItem } from "@mui/material";
import { useContext } from "react";
import { Context } from "../AuthState";
import { Constants, OTP_STATUS } from "../../../../utils/Constants";
import { useState } from "react";
import { useEffect } from "react";

const errorOnField = (hostInfo, key, pageIndex, type, setError) => {
  let valid = false;
  valid =
    hostInfo[pageIndex] &&
    hostInfo[pageIndex][key] &&
    hostInfo[pageIndex][key].length >= 1;
  if (type === "number") {
    valid = valid
      ? hostInfo[pageIndex][key].match(Constants.PHONE_NUMBER_REGEX)
      : false;
  }
  return !valid ? true : false;
};

const helperText = (type) => {
  if (type === "number") {
    return "";
  } else if (type === "date") {
    return "";
  } else if (type === "gender") {
    return "";
  } else {
    return "";
  }
};

const disableField = (otpVerified) => {
  if (otpVerified === OTP_STATUS.VERIFYING) {
    return true;
  }

  return false;
};

const PlayerInformation = ({ pageIndex, otpVerified, setError }) => {
  const [selected, setSelected] = useState({});
  let date = new Date()
    .toLocaleDateString()
    .toString()
    .replace("/", "-")
    .replace("/", "-")
    .split("-");
  const host = useContext(Context.HostInfo);
  const HandleUserInformation = (e, type) => {
    var phoneno = /^\d{10}$/;

    if (type === "gender") {
      setSelected({ ...selected, gender: true });
    } else if (type === "dob") {
      setSelected({ ...selected, dob: true });
    }
    if (!host.hostInfo[pageIndex]) {
      host.setHostInfo({ ...host.hostInfo, [pageIndex]: {} });
    }
    host.setHostInfo({
      ...host.hostInfo,
      [pageIndex]: {
        ...host.hostInfo[pageIndex],
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <PlayerInformationStyle selected={selected}>
      <div className="PlayerInformationForm">
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: false }}
          inputProps={{}}
          placeholder="Phone Number"
          type="tel"
          onChange={(e) => HandleUserInformation(e, "number")}
          name={`phoneNumber`}
          helperText={
            errorOnField(
              host.hostInfo,
              "phoneNumber",
              pageIndex,
              "number",
              setError
            )
              ? helperText("number")
              : ""
          }
          disabled={disableField(otpVerified)}
          value={
            host.hostInfo[pageIndex] && host.hostInfo[pageIndex].phoneNumber
              ? host.hostInfo[pageIndex].phoneNumber
              : ""
          }
          fullWidth
        />
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: false }}
          placeholder="Name"
          onChange={(e) => HandleUserInformation(e, "name")}
          name={`name`}
          helperText={
            errorOnField(host.hostInfo, "name", pageIndex, "name", setError)
              ? helperText("name")
              : ""
          }
          fullWidth
          disabled={disableField(otpVerified)}
          value={
            host.hostInfo[pageIndex] && host.hostInfo[pageIndex].name
              ? host.hostInfo[pageIndex].name
              : ""
          }
        />

        <FormControl fullWidth>
          <TextField
            id="gender"
            select
            variant="standard"
            onChange={(e) => HandleUserInformation(e, "gender")}
            name="gender"
            placeholder="Gender"
            style={selected["gender"] ? { opacity: "1" } : { opacity: "0.5" }}
            helperText={
              errorOnField(
                host.hostInfo,
                "gender",
                pageIndex,
                "gender",
                setError
              )
                ? helperText("gender")
                : ""
            }
            disabled={disableField(otpVerified)}
            value={
              host.hostInfo[pageIndex] && host.hostInfo[pageIndex].gender
                ? host.hostInfo[pageIndex].gender
                : "Gender"
            }
            size="small"
          >
            <MenuItem
              value={"Gender"}
              hidden={true}
              style={{ display: "none" }}
            >
              Gender
            </MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
            <MenuItem value={"others"}>Others</MenuItem>
          </TextField>
        </FormControl>
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: false }}
          onChange={(e) => HandleUserInformation(e, "dob")}
          name="dateOfBirth"
          placeholder={
            selected["dob"]
              ? host.hostInfo[pageIndex] && host.hostInfo[pageIndex].dateOfBirth
              : "DOB"
          }
          type="date"
          helperText={
            errorOnField(
              host.hostInfo,
              "dateOfBirth",
              pageIndex,
              "date",
              setError
            )
              ? helperText("date")
              : ""
          }
          inputProps={{
            max:
              date[2] +
              "-" +
              (date[0].length === 1 ? `0${date[0]}` : date[0]) +
              "-" +
              (date[1].length === 1 ? `0${date[1]}` : date[1]),
          }}
          disabled={disableField(otpVerified)}
          className={selected["dob"] ? "opacityin" : "opacityout"}
          fullWidth
        />
      </div>
    </PlayerInformationStyle>
  );
};

export default PlayerInformation;

const PlayerInformationStyle = styled.div`
  .PlayerInformationForm {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    box-sizing: border-box;
  }

  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    text-align: left;
  }

  input[type="date"]:before {
    content: attr(placeholder) !important;
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }

  input::-webkit-datetime-edit {
    color: transparent;
    user-select: none;
  }

  input[type="date"]:invalid::-webkit-datetime-edit {
    color: #999;
  }

  .opacityout input:before {
    opacity: 0.5;
  }
  .opacityin input:before {
    opacity: 1;
  }
`;
