const { postRequest } = require('./axiosClient');

module.exports.GAMES = {
  '643d08855731092343955149': 'Bowling',
  '643d0c37573109234395514b': 'Racing Simulator',
  '65a620fbedb75f193306dc32': 'Racing Simulator',
};

module.exports.SOCKET = "https://backend.projectteho.com";
// module.exports.SOCKET = 'http://localhost:8000';
// module.exports.SOCKET = 'http://192.168.8.0:8000/';

module.exports.URL = 'https://backend.projectteho.com/api';

module.exports.ScoreError = (scoreType, value) => {
  if (scoreType != 'time') return;

  let temp = value.split(':');
  if (!value.includes(':')) {
    return true;
  } else {
    return isNaN(temp[0]) || isNaN(temp[1]) || isNaN(temp[2]) || false;
  }
};

module.exports.OTP_TIMER = 30;

module.exports.Constants = {
  HOST_NAME_MAX_LENGTH: 1,
  PHONE_NUMBER_REGEX:
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  DATE_REGEX: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
  verify(obj) {
    let FIELDS = ['name', 'phoneNumber', 'dateOfBirth', 'gender'];
    let verified = true;
    for (const el of FIELDS) {
      if (!obj[el]) {
        verified = false;
        break;
      }
    }
    return verified;
  },

  RANGE_ARRAY: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};

module.exports.OTP_STATUS = {
  UNVERIFIED: 0,
  VERIFYING: 1,
  VERIFIED: 2,
};

module.exports.BUTTON_STATUS = {
  RETRIVE: 0,
  SHOW: 1,
};

module.exports.PAGE_STATE = {
  UNVERIFIED: 0,
  VERIFIED: 1,
  NOTLOADED: 2,
};

module.exports.CODE_STATUS = {
  SHOW: 0,
  PUBLISH: 1,
};

module.exports.GlobalDesigns = {
  OtpButtonDesign: {
    borderRadius: '21px',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    textTransform: 'none',
    width: '50%',
  },
  TosDesign: {},
  muiButtonTextDesign: {
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 1)',
    paddingLeft: '0.3rem',
    textTransform: 'none',
  },
  BtnDesign: {
    color: 'white',
    backgroundColor: 'black',
    padding: '0.4rem 2.5rem',
    borderRadius: '12px',
  },
};

module.exports.saveScore = (
  data,
  pageState,
  setPageState,
  setUser = false,
  user = false,
  score = false,
  filters,
  scoreType
) => {
  console.log(filters);
  const _Data = {
    filters:
      filters.currentFilter && filters.currentFilter.length > 1
        ? filters
        : { currentFilter: "Today 's View", gameId: data.gameId },
    time: Date.now(),
    scoreDetails: [],
    gameId: data.gameId,
    employeeId: data.employeeId,
  };
  for (const el in data) {
    if (!data[el]._id && !data[el].userId) continue;
    if (scoreType === 'time') {
      let a = data[el].score.split(':');
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      _Data.scoreDetails.push({
        userId: data[el]._id || data[el].userId,
        score: seconds.toString(),
      });
    } else {
      _Data.scoreDetails.push({
        userId: data[el]._id || data[el].userId,
        score: data[el].score,
      });
    }
  }

  let location = window.location;

  postRequest({
    endpoint: '/game/addScore',
    data: { ..._Data, scoreType: scoreType },
    dipatch: null,
    controller: new AbortController(),
    headers: {},
  })
    .then((response) => {
      if (response.status === 200) {
        setPageState({
          ...pageState,
          others: { error: false, msg: 'Published', type: 'score' },
        });
        if (setUser) {
          let user = localStorage.getItem('playerIds');
          user = JSON.parse(user);
          postRequest({
            endpoint: '/auth/playerinformation',
            dispatch: null,
            controller: new AbortController(),
            headers: {},
            data: {
              playerIds: [...user],
              emp: true,
              gameId: localStorage.getItem('gameId'),
              scoreType: scoreType,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                setUser(response.msg);
                localStorage.setItem('activePlayerId', response.msg[0]._id);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        setPageState({
          ...pageState,
          others: { error: true, msg: response.msg, type: 'score' },
        });
      }
    })
    .catch((err) => {
      setPageState({
        ...pageState,
        others: { error: true, msg: 'Server Error', type: 'score' },
      });
    });
};
