import { Button, TableBody, Table, TableContainer, TableHead, TableCell, TableRow } from '@mui/material';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../../utils/Constants';

const icons = ['', '']

const LeaderBoard = ({ playerLeaderBoardView, playerId, setPlayerId }) => {
  const [players, setPlayers] = useState(null); 
  let Players = localStorage.getItem('playerIds');
  Players = JSON.parse(Players); 
  useEffect(() => {
    let userId; 
    if (Players && Players.length === 1) {
      userId = Players[0]; 
    } else {
      userId = playerId;
    }
    axios.post(URL + '/game/getUserRep',  { 
      userId: userId
    })
    .then(response => {
      if (response.status === 200) {
        setPlayers(response.data);
      }
    })
    .catch(err => {
      setPlayers(null); 
      console.log(err); 
    })
  }, [playerId])

  return (
    <LeaderBoardStyle>
      <div className={playerLeaderBoardView ? 'PlayerLeaderBoard' : 'hidden' }>
        <div className='top' style={{ padding: '0 0.5rem' }}>
          <h2 style={{ textTransform: 'capitalize' }}>Hey {players && players.name ? players.name : ' '} </h2>
          <Button type='text' disableRipple 
          style={{ 
            fontSize: '13px',
            lineHeight: '16px',
            backgroundColor: 'transparent', textTransform: 'none', color: 'rgba(255, 255, 255, 1)', 
          fontWeight: '600',   }}
          >
          Check out your overall performance and 
          discover which game you excelled at the 
          most. Prepare yourself to play once 
          more and reach your goals.
          </Button>
        </div>
        <div className='scores'>
          <TableContainer  sx={{ paddingTop: '0.5rem' }}>
            <Table>
              <TableHead style={{ position: 'sticky', top: 0 }}>
                <TableRow >
                  <TableCell align='center' sx={{ fontWeight: '600', color: 'rgba(249, 173, 73, 1)' }}>Game</TableCell>
                  <TableCell align='center' sx={{ color: 'rgba(249, 173, 73, 1)'}} >Rank</TableCell>
                  <TableCell align='center' sx={{ color: 'rgba(249, 173, 73, 1)'}}>Score</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {players && players.games && players.games.length > 0 ? players.games.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell  align='left' sx={{ fontWeight: '600', color: 'inherit', maxWidth: '40px' }}>
                        <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center'}} >
                          {row.gameName}<img style={{ width: '12px', height: '15px' }} src={row.gameName === 'Bowling' ? 'bowl-icon.png' : 'race-icon.png'} /> 
                        </div>
                        </TableCell>
                      {/* {row.data && row.data.length > 0 ? 
                        row.data.map((d, i) => {
                          console.log(d);
                          return ( */}
                            {/* <div className='together'> */}
                              <TableCell align='center' sx={{ color: 'inherit'}}>{row.rank ? row.rank: ' '}</TableCell>
                              <TableCell align='center' sx={{ color: 'inherit'}}>{row.score ? row.score: ' '}</TableCell>      
                            {/* </div> */}
                          {/* )
                        })
                      : null} */}
                    </TableRow>
                  )
                }): null}
              </TableBody>
            </Table>
          </TableContainer>
          { players && players.bestGame ? 
            <div className='leaderboardText'>
              <p>Your Best Perfomed Game</p>
              <div style={{ maxWidth: 'fit-content' }}>
                <h4>{ players.bestGame}</h4>
                <img style={{ width: '35px', height: '35px' }} src={players.bestGame === 'Bowling' ? 'bowl-icon.png' : 'race-icon.png'} />
              </div>
            </div>
          : null }
            
        </div>
      </div>
    </LeaderBoardStyle>
  )
}

export default LeaderBoard; 


const LeaderBoardStyle = styled.div`


  .PlayerLeaderBoard {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    overflow: scroll;
  }

  .PlayerLeaderBoard .top {
    text-align: center;
    grid-column: 1 / -1;
    box-sizing: border-box;
    background: url(bg.png);
    background-color: rgba(0, 0, 0, 1);
    box-shadow: inset 2px 2px 15px 105px rgba(0, 0, 0, 0.4);
    height: 200px;
    background-repeat: no-repeat;
    display: flex; 
    gap: 9px;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-size: cover;
    backdrop-filter: opacity(50%);
  }

  .gameName {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
  }

  .hidden { 
    display: none;
  }

  h2 {
    background: linear-gradient(90deg, #F39A24 38.79%, #FFB74B 60.66%, #FFC737 87.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.8rem;
  }

  .together {
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
  }

  .scores {
    height: calc(100vh - 200px);
    background: url(Group.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 1);
    grid-column: 1 / -1;
    color: rgba(255, 255, 255, 1) !important;
    overflow-y: scroll; 
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
  }

  .leaderboardText {
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 1) !important; 
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .leaderboardText p {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    font-weight: 400; 
    margin: 0;
    padding: 0; 
  }


  .leaderboardText  h4 {
    color: white !important;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0;
  }

  .leaderboardText .logo {
    width: 40px;
  }

  .leaderboardText .logo img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .top h2 {
      font-size: 1.6rem
    }

    .top button {
      font-size: 0.8rem;
    }

    .leaderboardText  h4 {
      font-size: 23px;
      padding: 0.1rem;
    }

  }


`