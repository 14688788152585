import { withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import { postRequest } from '../../../../utils/axiosClient';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { saveScore } from '../../../../utils/Constants';
import { PageState } from '../../../../pageState';
import MuiTableCell from '@material-ui/core/TableCell';
import { ScoreError } from '../../../../utils/Constants';
import { TimeField } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getKiosk } from '../../../../utils/config';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 1)', // (default alpha is 0.38)
    },
  },
})(TextField);

const SinglePlayerInfo = ({ score, setScore, filters, setFilters }) => {
  const page = useContext(PageState.pageState);
  const { pageState, setPageState } = page;
  const [user, setUser] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [gameId, setGameId] = useState(null);
  const [currentPageState, setCurrentPageState] = useState('loading...');
  const [kiosk, setKiosk] = useState();
  useEffect(() => {
    console.log('kiosk', kiosk);
  }, [kiosk]);
  useEffect(() => {
    let user = localStorage.getItem('playerIds');
    user = JSON.parse(user);
    const setGetKioskAccept = (response) => {
      if (response.status === 200) {
        const index = response.data.message.findIndex(
          (el) => el.gameId === localStorage.getItem('gameId')
        );
        console.log('index', index);
        if (index >= 0) {
          setKiosk(response.data.message[index]);
          setGameId(localStorage.getItem('gameId'));
          if (!user) return;
          postRequest({
            endpoint: '/auth/playerinformation',
            dispatch: null,
            controller: new AbortController(),
            headers: {},
            data: {
              playerIds: [...user],
              emp: true,
              gameId: localStorage.getItem('gameId'),
              scoreType: response.data.message[index].scoreType
            },
          })
            .then((response) => {
              if (response.status === 200) {
                setUser(response.msg);
                setCurrentPageState('done');
                localStorage.setItem('activePlayerId', response.msg[0]._id);
              } else if (response.status === 404) {
                setCurrentPageState('Player not found');
              } else {
                setCurrentPageState('error occured');
              }
            })
            .catch((err) => {
              setCurrentPageState('error occured');
            });
        } else {
          setCurrentPageState('error');
        }
      } else {
        setCurrentPageState('error');
      }
    };
    const setGetKioskReject = () => {
      setCurrentPageState('error');
    };
    setCurrentPageState('loading');
    getKiosk('/kiosks', setGetKioskAccept, setGetKioskReject);
  }, []);

  useEffect(() => {
    console.log('kiosk', kiosk);
  }, [kiosk])

  if (currentPageState === 'loading') return <span>loading...</span>;
  return !user ? (
    <div>{currentPageState}</div>
  ) : (
    <PlayerInfoStyle style={{ height: '100%' }}>
      <div className="playerInfo">
        <div className="playerAction">
          <div className="aboutPlayer">
            <div className="playerAv">
              <img src={user[0].avatar} />
            </div>
            <div className="playerCode" style={{ visibility: 'hidden' }}>
              <Button type="text" sx={{ color: 'black' }} disableRipple>
                Player visiting pass code
              </Button>
              <TextField
                disabled
                value={user[0].code}
                size="large"
                variant="filled"
                style={{ width: '50%' }}
                InputProps={{
                  disableUnderline: true,
                  style: { textAlign: 'center', color: 'green' },
                }}
                className="textField"
                inputProps={{
                  style: { textAlign: 'center', fontWeight: '800' },
                }}
              />
            </div>
          </div>
          <div className="playerData">
            <DarkerDisabledTextField
              disabled
              value={user[0].name}
              name="name"
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="standard"
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
            <DarkerDisabledTextField
              disabled
              value={user[0].phoneNumber}
              name="number"
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="standard"
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
            <DarkerDisabledTextField
              disabled
              value={user[0].gender}
              name="gender"
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="standard"
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
            <DarkerDisabledTextField
              disabled
              value={new Date(user[0].dateOfBirth).toDateString()}
              name="dob"
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="standard"
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
          </div>
        </div>

        <div className="empAction" style={{ alignSelf: 'flex-end' }}>
          <div
            style={{ height: '2rem', width: '100%', backgroundColor: 'white' }}
          ></div>
          <div
            className="Attempts"
            style={{
              alignSelf: 'flex-end',
              backgroundColor: 'white',
              zIndex: '2',
              overflowY: 'scroll',
              height: '150px',
            }}
          >
            <div>
              <div
                style={{
                  position: 'sticky',
                  top: 0,
                  fontWeight: '800',
                  backgroundColor: 'white',
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Attempts
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Score
                  </div>
                </div>
              </div>
              <div>
                {user[1] && user[1].attempts && user[1].attempts.length >= 1
                  ? [...user[1].attempts].reverse().map((attempt, i) => {
                      return (
                        <div
                          onClick={() => {
                            if (activeIndex === i) {
                              setActiveIndex(-1);
                            } else {
                              localStorage.setItem(
                                'activePlayerEvent',
                                attempt.eventId
                              );
                              setActiveIndex(i);
                            }
                          }}
                          key={attempt.eventId}
                          className={i === activeIndex ? 'activeAttempt' : null}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            width: '100%',
                            height: '45px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {user[1].attempts.length - i}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {attempt.score}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>

          <div className="EnterScore" style={{ alignSelf: 'flex-end' }}>
            {kiosk.scoreType === 'time' ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  style={{ width: '100%' }}
                  error={
                    score[0] && score[0].score
                      ? ScoreError(score[0].score)
                      : false
                  }
                  // value={(score[0] && score[0].score) ? score[0].score :  '' }
                  onInput={(e) => {
                    setScore({
                      ...score,
                      0: { userId: user[0]._id, score: e.target.value },
                    });
                  }}
                  format="hh:mm:ss"
                  size="small"
                />
              </LocalizationProvider>
            ) : (
              <TextField
                type={'number'}
                name="score"
                value={
                  score[0] && score[0].score
                    ? score[0].score
                    : kiosk.scoreType === 'time'
                    ? '00:00:00'
                    : ''
                }
                variant="outlined"
                size="small"
                placeholder="score"
                inputProps={{ step: '1', autoComplete: 'off' }}
                fullWidth
                error={
                  score[0] && score[0].score
                    ? ScoreError(score[0].score)
                    : false
                }
                onChange={(e) => {
                  setScore({
                    ...score,
                    0: { userId: user[0]._id, score: e.target.value },
                  });
                }}
              />
            )}

            <Button
              sx={{
                color: 'white',
                backgroundColor: 'black',
                padding: '0.2rem 1rem',
              }}
              disableRipple
              onClick={(e) => {
                saveScore(
                  score,
                  pageState,
                  setPageState,
                  setUser,
                  user,
                  score[0].score,
                  filters, 
                  kiosk.scoreType || "number"
                );
                setScore({ ...score, 0: { userId: user[0]._id, score: '' } });
                e.target.style.color = 'white';
              }}
              className="btn"
              variant="contained"
              disabled={
                !(
                  score[0] &&
                  score[0].score &&
                  score[0].score.length > 1 &&
                  !ScoreError(kiosk.scoreType, score[0].score)
                )
              }
            >
              Publish
            </Button>
          </div>
        </div>
      </div>
    </PlayerInfoStyle>
  );
};

export default SinglePlayerInfo;

const PlayerInfoStyle = styled.div`
  .playerInfo {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    height: 100%;
  }

  .playerAction {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }

  .empAction {
    grid-column: 1 / -1;
  }

  .activeAttempt {
    background-color: #f9ad49;
  }

  .btn:hover {
    background-color: black;
  }

  .aboutPlayer {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  .EnterScore {
    display: flex;
    justify-content: center;
    padding: 0rem 1rem;
    gap: 0.5rem;
    margin-bottom: 100px;
  }

  @media screen and (max-height: 800px) {
    .EnterScore {
      padding-bottom: 50px;
    }
  }

  .playerData {
    grid-column: 1 / -1;
  }

  img {
    box-shadow: 2px 2px 25px 10px rgba(151, 225, 194, 1);
  }

  .textField {
    font-size: 2rem;
  }

  .playerCode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .playerAv {
    width: 120px;
    height: 100px;
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .playerData {
    grid-column: 2 / 8;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
  }

  .MuiFilledInput-input {
    padding: 10px;
  }

  @media screen and (max-width: 600px) {
    img {
      box-shadow: 2px 2px 15px 10px rgba(151, 225, 194, 1);
    }
    .playerCode button {
      font-size: 10px;
    }

    .playerAction {
      padding-top: 0.5rem;
      justify-content: flex-start;
      align-items: start;
    }

    .playerAv {
      width: 50px;
      height: 50px;
      padding: 0rem;
    }
    .aboutPlayer,
    .playerData {
      padding-top: 0.7rem;
    }

    .aboutPlayer {
      grid-column: 1 / 4;
      flex-direction: column;
      font-size: 0.8rem;
      justify-content: center;
    }

    .playerData {
      grid-column: 4 / 8;
      gap: 0.7rem;
    }

    .playerData div div input {
      font-size: 0.9rem;
      padding: 4px 0px;
    }
  }
`;
