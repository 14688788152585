import styled from 'styled-components';


const Topbar = ({filters=false}) => {
  return (
    <TopbarStyle>
      <div className='Topbar'>
        <div className='CompanyLogo'>
          <img src='top-bar.png' />
        </div>

        {filters? <div className='TopBar'>
          <p>{filters.currentFilter && filters.currentFilter.length > 1 ? filters.currentFilter : 'Today \'s View'}</p>
        </div> : null}

        <div className='SponserInfo'>
          <span>Powered By</span>
          <div>
            <img src='top-bar-2.png' />
          </div>
        </div>
      </div>
    </TopbarStyle>
  )
}

export default Topbar;


const TopbarStyle = styled.div`
  .Topbar {
    width: 100%;
    border-bottom: 2px solid black;
    padding: 0.7rem 0.5rem 0.4rem 0.5rem;
    box-sizing: border-box;
    z-index: 3;
    display: flex;
    justify-content: space-between;
  }
 
  .CompanyLogo {
    display: inline;
  }

  .SponserInfo {
    float: right;
    margin-right: 0.4rem;
    margin-top: 0.2rem;
    display: flex; 
    justiy-content: center; 
    padding-top: 5px;
  }

  .SponserInfo span {
    margin-right: 0.4rem;
  }


`