import MultiPlayerInfo from "./MultiPlayerInfo";
import SinglePlayerInfo from "./SinglePlayerInfo";
import { useEffect, useState } from "react";
import { accessByEmployee } from "../../../../utils/access";

const EmpPlayerInfo = ({ branchId, leaderboardView, filters, setFilters }) => {
  const [score, setScore] = useState({});
  const [playerIds, setPlayerIds] = useState(null);
  let gameId = localStorage.getItem('gameId');
  let empId = localStorage.getItem('empId');

  useEffect(() => {
    const token = localStorage.getItem('empToken');
    if (!token) {
      window.location = '/emplogin?branchid=' + branchId;
    } else {
      accessByEmployee({
        token: token,
        type: 'emp',
        success: (response) => { console.log(response) },
        failure: () => window.location = '/emplogin?branchid=' + branchId
      });
    }

    setScore({ gameId: gameId, employeeId: empId, scoreDetails: [] });
    let playerIds = localStorage.getItem('playerIds');
    playerIds = JSON.parse(playerIds);
    setPlayerIds(playerIds);
  }, []);


  if (playerIds && playerIds.length <= 1 && !leaderboardView) {
    return (
      <SinglePlayerInfo branchId={branchId} score={score} setScore={setScore} filters={filters} setFilters={setFilters} />
    )
  } else if (playerIds && !leaderboardView && playerIds.length > 1) {
    return (
      <MultiPlayerInfo branchId={branchId} score={score} setScore={setScore} filters={filters} />
    )
  }
}


export default EmpPlayerInfo; 