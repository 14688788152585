import styled from 'styled-components';
import {
  Button,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
} from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import MuiTableCell from '@material-ui/core/TableCell';
import { useRef, useEffect, useState } from 'react';
import { sendRequest } from '../../../customComponent/EmpNav';
import Video from '../../../../utils/Video';
import { useSearchParams } from 'react-router-dom';
import socket from '../../../../socket';
import io from 'socket.io-client';
import { URL, SOCKET } from '../../../../utils/Constants';
import './leaderBoardAll.css';
import { getKiosk } from '../../../../utils/config';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const display = ['all1.mp4', 'all2.mp4'];

const LeaderBoardAll = ({
  style = false,
  filters,
  setFilters,
  leaderboardView,
  mainPage,
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [players, setPlayers] = useState([]);
  const tableCellRef = useRef(0);
  const [sock, setSock] = useState(null);
  const someRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [kiosk, setKiosk] = useState();

  // const [heightState, setHeightState] = useState();
  useEffect(() => {
    const socket = io(SOCKET);
    socket.connect();
    let gameId = searchParam.get('gameid');
    if (!gameId) return;
    socket.on('connect', () => console.log('connected'));

    socket.on('switch', (data) => {
      if (!data.msg) return;
      if (data.gameId && data.gameId === gameId) {
        setFilters({
          currentFilter: data.msg,
          gameId: gameId,
          activePlayerEventId: data.activePlayerEventId || null,
        });
        let location = window.location;
        if (data.type && data.type === 'reload') {
          window.location = location;
        }
      }
    });

    socket.on('addplayer', (data) => {
      let gameId = searchParam.get('gameid');
      if (data.data.length <= 0) return;
      if (
        gameId &&
        gameId === data.gameId &&
        data.data &&
        data.data.length >= 1
      ) {
        if (data.data.length === 1) {
          handleClick(data.data[0]);
        } else {
          for (const el of data.data) {
            handleClick(el);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const onAcceptGetKiosk = (response) => {
      if (response.status === 200) {
        const index = response.data.message.findIndex(
          (el) => el.gameId === localStorage.getItem('gameId')
        );
        console.log('index', index);
        if (index >= 0) {
          setKiosk(response.data.message[index]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const onRejectGetKiosk = (e) => {
      console.log(e);
      setLoading(false);
    };
    setLoading(true);
    getKiosk('/kiosks', onAcceptGetKiosk, onRejectGetKiosk);
  }, []);

  const highlighUser = async () => {
    let m = document.querySelector('#tbScrool');
    console.log('scrolled');
    m.scrollTo({ top: 0 });
    let cellTop = tableCellRef.current.offsetTop;
    // const index = tableCellRef.current.rowIndex;
    console.log(Math.floor(index / 10));
    let tableBody = document.querySelector('.tableBody');
    var index = Array.prototype.indexOf.call(
      tableBody.children,
      tableCellRef.current
    );
    console.log((Math.ceil(index / 10) - 1) * 10, index);
    const el1 = tableBody.children[(Math.ceil(index / 10) - 1) * 10];
    cellTop = el1.offsetHeight * ((Math.ceil(index / 10) - 1) * 10);
    console.log(el1);
    console.log(el1.offsetTop);
    let table = Array.from(tableBody.children);
    let result = cellTop;
    let ms = m.scrollTop;
    let id = setInterval(async () => {
      if (ms >= result) {
        clearInterval(id);
      }
      ms = ms + 10;
      m.scrollTo({ top: ms });
    }, 10);
  };

  useEffect(() => {
    console.log(filters);
    if (!kiosk) return;
    let gameIdQuery = searchParam.get('gameid') || null;
    sendRequest(
      filters.currentFilter.length > 1
        ? filters
        : {
            currentFilter: "Today's View",
            gameId: gameIdQuery,
          },
      kiosk.scoreType || 'number'
    ).then((response) => {
      setPlayers(response.data);
      if (filters.activePlayerEventId) {
        highlighUser();
      }
    });
  }, [filters]);

  const handleClick = (data) => {
    let index = data.rank;
    setFilters({
      currentFilter:
        filters.currentFilter && filters.currentFilter.length > 1
          ? filters.currentFilter
          : "Today's View",
      gameId: searchParam.get('gameid'),
    });
    animatePlayer(index);
  };

  useEffect(() => {
    let tableBody = document.querySelector('.tableBody');
    let table = Array.from(tableBody.children);
    setTimeout(() => {
      // do something 1 sec after clicked has changed
      table.forEach((element) => {
        element.classList.remove('animationUp');
        element.classList.remove('animationDown');
      });
    }, 6000);
  }, [players]);

  const animatePlayer = (index) => {
    let m = document.querySelector('.scores');
    let tableBody = document.querySelector('.tableBody');
    let table = Array.from(tableBody.children);
    // m.scrollTo({ top: tableEl.offsetTop - 280 });
    const el1 = tableBody.children[index];
    const el2 = tableBody.children[index + 1];
    const el3 = tableBody.children[index + 2];
    const el4 = tableBody.children[index + 3];
    const el5 = tableBody.children[index + 4];
    const el6 = tableBody.children[index + 5];
    const el7 = tableBody.children[index + 6];
    const el8 = tableBody.children[index + 7];
    const el9 = tableBody.children[index + 8];
    const el10 = tableBody.children[index + 9];

    el1.classList.add('animationUp');
    el2.classList.add('animationDown');
    el3.classList.add('animationDown');
    el4.classList.add('animationDown');
    el5.classList.add('animationDown');
    el6.classList.add('animationDown');
    el7.classList.add('animationDown');
    el8.classList.add('animationDown');
    el9.classList.add('animationDown');
    el10.classList.add('animationDown');
    tableBody = document.querySelector('.tableBody');
    tableBody.forEach((element) => {
      element.classList.remove('animationUp');
      element.classList.remove('animationDown');
    });
    el1.addEventListener('animationend', () => {
      el1.classList.remove('animationUp');
    });
    el2.addEventListener('animationend', () => {
      el2.classList.remove('animationDown');
    });
    el3.addEventListener('animationend', () => {
      el3.classList.remove('animationDown');
    });
    el4.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el5.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el6.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el7.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el8.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el9.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
    el10.addEventListener('animationend', () => {
      el4.classList.remove('animationDown');
    });
  };
  const headerFont = 1.2;
  if (loading) return <span>loading...</span>;

  return (
    <div
      className={leaderboardView || mainPage ? 'PlayerLeaderBoard' : 'hidden'}
      style={style ? style : null}
    >
      <div className="top">
        <Video videos={display} />
      </div>
      <div className="scores" id="score">
        <div className="table-no-highlight" id="nonspacetable">
          <div className="tableTop">
            <div className="tabEle tabEle1 tabEleHeaD">Rank</div>
            <div className="tabEle tabEle2 tabEleHeaD">Name</div>
            <div className="tabEle tabEle3 tabEleHeaD">Score</div>
          </div>

          <div className="tableBody" id="tbScrool">
            {players &&
              players.map((row, i) => {
                return (
                  <div
                    style={
                      row._id === filters.activePlayerEventId
                        ? { backgroundColor: '#f9ad49' }
                        : null
                    }
                    ref={
                      row._id === filters.activePlayerEventId
                        ? tableCellRef
                        : someRef
                    }
                    key={i}
                    className={
                      i == 0
                        ? 'firstLb tbleBody'
                        : i == 1
                        ? 'secondLb tbleBody'
                        : i == 2
                        ? 'thirdLb tbleBody'
                        : 'tbleBody'
                    }
                  >
                    <div
                      align="center"
                      size="small"
                      // style={{ fontSize: "2.4em" }}
                      sx={{ color: 'inherit', backgroundColor: 'red' }}
                      className="tabEle tabEle1 tabEleBdy"
                    >
                      {i + 1}
                    </div>
                    <div
                      align="center"
                      size="small"
                      sx={{ color: 'inherit' }}
                      className="tabEle tabEle2 tabEleBdy"
                    >
                      {' '}
                      <div className="userName">
                        <img src={row.avatar} /> {row.name}
                      </div>
                    </div>
                    <div
                      align="center"
                      size="small"
                      sx={{ color: 'inherit' }}
                      className="tabEle tabEle3 tabEleBdy"
                    >
                      {row.score}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="higlightsection"></div>
        <div
          className="bottomaddScroll"
          style={{ backgroundColor: 'white' }}
        ></div>
      </div>
    </div>
  );
};

export default LeaderBoardAll;
