import { Button, FormControl, InputLabel, TextField } from '@mui/material';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import SelectGames from '../../../customComponent/SelectGames';
import Otp from '../../../customComponent/Otp';
import { useState, useContext, useEffect } from 'react';
import { EmpContext } from '../empInfo';
import { getOtp } from '../../../../utils/otp';
import { postRequest } from '../../../../utils/axiosClient';
import { OTP_STATUS, PAGE_STATE } from '../../../../utils/Constants';
import { PageState } from '../../../../pageState';
import { accessByEmployee } from '../../../../utils/access';
import { getKiosk } from '../../../../utils/config';

const CssTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      color: 'white', // (default alpha is 0.38)
      outline: 'none !important',
      border: 'none',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'yellow',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'yellow',
        },
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: '2px white color',
    },
  },
})(TextField);

const getAllGames = (setGames, setPageState) => {
  setPageState({ message: '', state: PAGE_STATE.NOTLOADED });
  const onAcceptGetKiosk = (response) => {
    if (response.status === 200) {
      const games = [];
      for (const el of response.data.message) {
        games.push({ gameName: el.name, _id: el.gameId, customName: el.customId });
      }
      setGames(games);
      setPageState({ message: '', state: PAGE_STATE.VERIFIED });
    } else {
      console.log(response);
      setPageState({ message: '', state: PAGE_STATE.UNVERIFIED });
    }
  };
  const onRejectGetKiosk = () => {};
  getKiosk('/kiosks', onAcceptGetKiosk, onRejectGetKiosk);
};

const checkEmpLogin = (token, branchId, gameId, employeeId) => {
  accessByEmployee({
    token: token,
    type: 'emp',
    success: () => {
      window.location = '/empdashboard?branchid=' + branchId;
    },
    failure: (err) => {},
  });
};

const Login = ({ branchId }) => {
  const [otpStatus, setOtpStatus] = useState(OTP_STATUS.UNVERIFIED);
  const [otp, setOtp] = useState(null);
  const emp = useContext(EmpContext.EmpInfo);
  const [games, setGames] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const page = useContext(PageState.pageState);
  const { pageState, setPageState } = page;

  const clickHandler = (e) => {
    postRequest({
      endpoint: '/auth/emplogin',
      dispatch: null,
      headers: {},
      data: { empNumber: emp.empInfo.empNumber },
      controller: new AbortController(),
    })
      .then((response) => {
        console.log(emp.empInfo.loginFor);
        if (response.status === 200) {
          localStorage.setItem('empToken', response.msg.token);
          localStorage.setItem('empId', response.msg.emp._id);
          localStorage.setItem('gameId', emp.empInfo.loginFor._id);
          window.location = '/empdashboard?branchid=' + branchId;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let token = localStorage.getItem('empToken');
    if (token) {
      checkEmpLogin(token, branchId);
    }
    getAllGames(setGames, setPageState);
  }, []);

  const otpHandler = async () => {
    if (emp.empInfo.empNumber.length != 10) return;
    try {
      // let result = await getOtp(emp.empInfo.empNumber);
      if (true) {
        setOtpStatus(OTP_STATUS.VERIFYING);
        setOtp(1234);
        // setPageState({
        //   ...pageState,
        //   others: { msg: "Otp Send!", error: false },
        // });
      } else {
        console.log('server error');
        setPageState({
          ...pageState,
          others: { msg: 'Server Error!', error: false },
        });
      }
    } catch (e) {
      console.log(e);
      setPageState({
        ...pageState,
        others: { msg: 'Server Error!', error: false },
      });
    }
  };

  if (pageState.state === 'loading') return <span>loading</span>;

  return (
    <EmployeeLoginStyle>
      <div className="employeeLogin">
        <div
          style={{ borderBottom: 'none', textAlign: 'center' }}
          className={otpStatus === OTP_STATUS.VERIFIED ? 'hidden' : 'loginInfo'}
        >
          <span style={{ color: 'white', fontWeight: '600' }}>
            ENTER YOUR REGISTERED NUMBER
          </span>
          <CssTextField
            sx={{
              color: 'white',
              marginTop: '0.8rem',
              outline: 'none',
              padding: '0.3rem 0.5rem',
            }}
            id="number"
            type="tel"
            error={
              emp.empInfo.empNumber.length != 10 &&
              emp.empInfo.empNumber.length >= 1
            }
            size="small"
            variant="outlined"
            inputProps={{
              disableUnderline: false,
              color: 'white',
              maxLength: 10,
              color: 'white',
            }}
            helperText={
              emp.empInfo.empNumber.length != 10 &&
              emp.empInfo.empNumber.length >= 1
                ? 'Incorrect phone number'
                : ''
            }
            autoComplete="none"
            value={emp.empInfo.empNumber}
            disabled={otpStatus === OTP_STATUS.VERIFYING}
            onChange={(e) =>
              emp.setEmpInfo({ ...emp.empInfo, empNumber: e.target.value })
            }
            fullWidth
          />
          <div style={{ paddingTop: '0.4rem' }}>
            <Button
              sx={{ color: 'white' }}
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              variant="contained"
              disableRipple
              disabled={otpStatus === OTP_STATUS.VERIFYING}
              onClick={otpHandler}
            >
              Get Otp
            </Button>
          </div>
        </div>

        <div className={otpStatus == OTP_STATUS.VERIFYING ? 'otp' : 'hidden'}>
          <Otp
            otpValue={otp}
            otpVerified={otpStatus}
            setOtpVerified={setOtpStatus}
            successValue={OTP_STATUS.VERIFIED}
            setOtpValue={setOtp}
            phoneNumber={emp.empInfo.empNumber}
            emp={true}
          />
        </div>

        <div
          className={
            otpStatus === OTP_STATUS.VERIFIED ? 'selectGame' : 'hidden'
          }
        >
          <span>Login For</span>
          <SelectGames
            hoverColor={'rgba(249, 173, 73, 1)'}
            empInfo={emp.empInfo}
            setEmpInfo={emp.setEmpInfo}
            games={games}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>

        <div
          onClick={async (e) => {
            if (otpStatus != OTP_STATUS.VERIFIED) return;
            if (!emp.empInfo.loginFor._id) return alert('Select any game!')
            await clickHandler(e);
          }}
          className={otpStatus != OTP_STATUS.VERIFIED ? 'hidden' : 'login'}
        >
          <div className="start">Start</div>
        </div>
      </div>
    </EmployeeLoginStyle>
  );
};

export default Login;

const EmployeeLoginStyle = styled.div`
  .employeeLogin {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto 1fr;
    background-color: rgba(27, 27, 27, 1);
    height: 100vh;
    grid-gap: 1rem 1rem;
  }
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid white;
    border-radius: 12px;
  }

  .loginInfo {
    grid-column: 2 / 8;
    color: #fffff !important;
    padding-top: 5rem;
  }

  .otp {
    grid-column: 3 / 7;
    padding-top: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }

  .selectGame {
    grid-column: 1/-1;
    color: white;
    font-weight: 600;
    overflow: auto; 
    padding-top: 4rem;
    text-transform: uppercase;
  }

  .selectGame span {
    padding: 0.5rem 1.2rem;
  }

  .hidden {
    display: none;
  }

  .login {
    grid-column: 2 / 8;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1rem;
  }

  .start {
    width: 100%;
    background-color: rgba(192, 188, 188, 1);
    text-align: center;
    padding: 0.6rem 0rem;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 12px;
  }

  .unverified {
    color: rgba(0, 0, 0, 0.7);
  }

  .css-1i9zlvb-MuiFormControl-root-MuiTextField-root {
    padding: 0.2rem 0;
  }
`;
