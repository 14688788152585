import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from 'styled-components';
import { Button } from '@mui/material'
import { Context } from '../../user/AuthState';
import { useContext, useState } from "react";
import { TableBody, TableContainer, Table, TableRow, TableHead } from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import { useEffect } from "react";
import { postRequest } from "../../../../utils/axiosClient";
import { GlobalDesigns, PAGE_STATE, ScoreError } from "../../../../utils/Constants";
import { PageState } from "../../../../pageState";
import { saveScore, GAMES } from "../../../../utils/Constants";
import { TimeField } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

//fetch host information from some connected state
const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.9)", // (default alpha is 0.38)
      backgroundColor: 'rgba(238, 238, 238, 1)'
    }
  }
})(TextField);




const MultiPlayerInfo = ({ branchId, score, setScore, filters }) => {
  const [players, setPlayers] = useState([]);
  const [hostInfo, setHostInfo] = useState({ hostName: '', hostNumber: '' });
  const [screenSize, setScreenSize] = useState(null);
  const page = useContext(PageState.pageState);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { pageState, setPageState } = page;
  const [gameId, setGameId] = useState(null);
  const checkFields = () => {
    let mappedObj = (Object.keys(score)).filter(el => score[el].score);
    return mappedObj.length != players.length;
  }



  useEffect(() => {
    setScreenSize(window.innerWidth);
    let playerIds = localStorage.getItem('playerIds');
    let host = localStorage.getItem('hostInfo');
    host = JSON.parse(host);
    playerIds = JSON.parse(playerIds);
    setGameId(localStorage.getItem('gameId'));

    if (!hostInfo || hostInfo.length == 0) {
      setHostInfo({ hostName: '', hostNumber: '' });
    } else {
      setHostInfo({ hostName: host.name || host.hostName, hostNumber: host.phoneNumber || host.hostPhoneNumber || host.hostNumber });
    }

    if (!playerIds || playerIds.length <= 0) {
    } else {
      postRequest({
        endpoint: '/auth/playerinformation',
        dispatch: null,
        controller: new AbortController(),
        data: { playerIds: playerIds },
        headers: {}
      })
        .then(response => {
          if (response.status === 200) {
            setPlayers(response.msg);
            setPageState({ msg: 'Loaded', state: PAGE_STATE.VERIFIED });
          } else {
            setPageState({ msg: 'Error occured', state: PAGE_STATE.UNVERIFIED });
          }
        })
        .catch(err => {
          console.log(err);
          setPageState({ msg: 'Error Occured', state: PAGE_STATE.UNVERIFIED });
        })
    }
  }, [])


  if (pageState.state != PAGE_STATE.VERIFIED) {
    return (
      <h1>{pageState.msg}</h1>
    )
  }
  return (
    <>
      <PlayerCodeStyle>
        <div className='PlayerCode' style={{ overflow: 'hidden' }}>

          <div className='HostInfo'>
            <DarkerDisabledTextField
              disabled
              variant='filled'
              value={hostInfo.hostName}
              InputProps={{ style: { height: '40px', paddingBottom: '0.5rem', borderRadius: '8px' }, disableUnderline: true }}
              name='name'
              fullWidth
            />
            <DarkerDisabledTextField
              disabled
              variant='filled'
              value={hostInfo.hostNumber}
              InputProps={{ style: { height: '40px', paddingBottom: '0.7rem', borderRadius: '8px' }, disableUnderline: true }}
              name='number'
              fullWidth
            />
          </div>
          <div className='PlayersInfo'>
            <Button type='text' disableRipple style={{ backgroundColor: 'transparent' }}>You are Hosting Following Players</Button>
            <div className='Players' style={{ overflowX: 'scroll' }} >
              <TableContainer style={{ minWidth: '600px', textAlign: 'center' }}>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: 'rgba(249, 173, 73, 0.75)' }}>
                      <TableCell size='small' align='center'
                        width={screenSize < 500 ? '10px' : '30px'}
                      >No</TableCell>
                      <TableCell align='center' size='small'
                        width='60px'
                      >Code</TableCell>
                      <TableCell align='center' size='small'
                        width={screenSize < 500 ? '30px' : '40px'}
                      > </TableCell>
                      <TableCell align='center' size='small'
                        width={screenSize < 500 ? '100px' : '250px'}
                      >Name</TableCell>
                      <TableCell align='center' size='small'
                      >Gender</TableCell>
                      <TableCell align='center' size='small'
                        width={screenSize < 500 ? '100px' : '250px'}
                      >Score</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {players.map((row, i) => {
                      return (
                        <TableRow key={i}
                          style={activeIndex === i ? { backgroundColor: 'grey' } : null}
                        >
                          <TableCell size='small'
                            onClick={() => {
                              localStorage.setItem('activePlayerId', row._id)
                              setActiveIndex(i)
                            }}
                            align='center' >P{i + 1}</TableCell>
                          <TableCell align='center' size='medium'>
                            <TextField
                              value={row.code}
                              disabled={true}
                              variant='filled'
                              InputProps={{ disableUnderline: true }}
                              style={{ textAlign: 'center' }}
                              sx={{ textAlign: 'center' }}
                              size='small'
                              name='code'
                            />
                          </TableCell>

                          <TableCell>
                            <img src={row.avatar} />
                          </TableCell>

                          <TableCell align='center' size='small'>
                            <TextField
                              value={row.name}
                              disabled={true}
                              variant='outlined'
                              sx={{ textAlign: 'center' }}
                              size='small'
                              name='name'
                            />
                          </TableCell>

                          <TableCell align='center' size='small' >
                            {row.gender}
                          </TableCell>

                          <TableCell align='center' size='small'>


                            {gameId && gameId === '643d0c37573109234395514b' ?
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimeField
                                  style={{ width: '100%' }}
                                  error={score[i] && score[i].score ? ScoreError(score[i].score) : false}
                                  onInput={(e) => {
                                    setScore({ ...score, [i]: { userId: row._id, score: e.target.value } })
                                  }}
                                  format="hh:mm:ss"
                                  size='small'
                                />
                              </LocalizationProvider>
                              :
                              <TextField
                                variant='outlined'
                                size='small'
                                value={score[i] && score[i].score ? score[i].score : gameId && gameId === '643d0c37573109234395514b' ? '00:00:00' : ''}
                                name='score'
                                error={score[i] && score[i].score ? ScoreError(score[i].score) : false}
                                type={gameId && gameId === '643d0c37573109234395514b' ? 'time' : gameId && gameId === '643d08855731092343955149' ? 'number' : 'text'}
                                disabled={activeIndex === i}
                                onChange={(e) => {
                                  setScore({ ...score, [i]: { score: e.target.value, userId: row._id } });
                                }}
                                fullWidth
                              />
                            }

                          </TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
          <div className='PublishButton'>
            <Button
              onClick={() => saveScore(score, pageState, setPageState, false, false, false, filters)}
              className='btn'
              variant='contained'
              disabled={checkFields()}
              disabledRipple
              sx={GlobalDesigns.BtnDesign}>Publish
            </Button>
          </div>

        </div>
      </PlayerCodeStyle>
    </>
  )
}


export default MultiPlayerInfo;

const PlayerCodeStyle = styled.div`
  .PlayerCode { 
    display: grid; 
    grid-template-columns: 100px repeat(7, 1fr); 
    text-align: center; 
  }


  .HostInfo {
    z-index: -1;
    grid-column: 1 / -1;
    display: flex; 
    flex-direction: column; 
    gap: 0.4rem;
    padding: 0.7rem 0.5rem 0rem 0.5rem;
  }


  input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  .PlayersInfo {
    grid-column: 1 / -1;
    text-align: left;
    padding-top: 0.7rem;
  }


  .PlayersInfo Button {
    text-transform: none;
    letter-spacing: 1.5px;
    color: rgba(0, 0, 0, 1); 

  }

  img {
    border-radius: 50%; 
    width: 35px; 

  }

  .btn:hover {
    background-color: black;
  }

  .Players {
    padding-top: 0.2rem;
    font-weight: 400; 
    color: rgba(0, 0, 0, 0.1) !important;
    max-height: 300px; 
  }


  @media screen and (max-width: 600px) {
    .Players {
      max-height: 200px;
    }
  }
  .PublishButton {
    grid-column: 1 / -1;
    padding-top: 1rem; 
    display: flex; 
    justify-content: center; 
  }

  .PublishButton:hover Button {
    background-color: black;
  }


  .UserAvName {
    display: flex; 
    gap: 0.2rem;
  }

`