import { createContext } from "react";

export class PageState {
    static pageState = createContext();
}





