import { Button } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { postRequest } from '../../../../utils/axiosClient';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { GlobalDesigns } from '../../../../utils/Constants';
import TextField from "@material-ui/core/TextField";


const DarkerDisabledTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 1)", // (default alpha is 0.38)
    }
  }
})(TextField);

const SinglePlayerInfo =  ({ branchId }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem('playerIds');
    user = JSON.parse(user);

    if (!user) return;
    postRequest ({
      endpoint: '/auth/playerinformation', 
      dispatch: null,
      controller: new AbortController(), 
      headers: {}, 
      data: { playerIds: [...user] }
    })
    .then(response => {
      if (response.status === 200){
        setUser(response.msg[0]);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, [])
	
	
	return (
    !user ? <div>Loading....</div> :
    <PlayerInfoStyle>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      <div className='playerInfo'  >
        <div className='aboutPlayer'>
          <div className='playerAv'>
            <img src={user.avatar}  />
          </div>
          <div className='playerCode'>
            <Button type='text' sx={{ color: 'black', fontSize: '0.8rem', padding: '0.5rem', width: '100%', textTransform: 'none' }}  disableRipple>Your Pass Code</Button>
            <DarkerDisabledTextField 
              disabled value={user.code} size='small' variant='filled'               
              style={{ width: '100%', borderRadius: '8px' }} 
              InputProps={{ disableUnderline: true, style: { textAlign: 'center', color: 'green' } }} 
              className='textField'
              inputProps={{style: { padding: '0.5rem', textAlign: 'center', fontWeight: '800', fontSize: '25px' }}} />
          </div>
        </div>
        <div className='playerData'>
            <DarkerDisabledTextField 
              disabled 
              value={user.name} 
              name='name'  
              fullWidth  
              InputProps={{ disableUnderline: true, style: { textTransform: 'capitalize', fontSize: '14px' } }}  
              variant='standard' 
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem', textTransform: 'capitalize' }}
            />
            <DarkerDisabledTextField 
              disabled 
              value={user.phoneNumber} 
              name='number' 
              fullWidth  
              InputProps={{ disableUnderline: true, style: { fontSize: '14px'} }}  
              variant='standard' 
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
            <DarkerDisabledTextField 
              disabled 
              value={user.gender} 
              name='gender' 
              fullWidth  
              InputProps={{ disableUnderline: true, style: { fontSize: '14px'} }}  
              variant='standard' 
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }}
            />
            <DarkerDisabledTextField 
              disabled 
              value={new Date(user.dateOfBirth).toDateString()} 
              name='dob' 
              fullWidth  
              InputProps={{ disableUnderline: true, style: { fontSize: '14px'} }}  
              variant='standard' 
              style={{ borderBottom: '1px solid black', paddingLeft: '0.3rem' }} 
            />
        </div>
      </div>
      </div>
      
    </PlayerInfoStyle> 
	)   
}


export default SinglePlayerInfo;

const PlayerInfoStyle = styled.div`
  .playerInfo {
    display: flex; 
    flex-direction: column;
    width: calc( (306 /343 ) * 100%  );
  }

  .aboutPlayer {
    grid-column: 1 / -1;
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input[name="name"] {
    text-transform: capitalize
  }


  .playerCode {
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .playerAv {
    // grid-column: 
    width: 120px;
    height:120px;
    padding: 1rem;
  }  

  .playerData {
    padding: 0rem 0rem;
  }

  img {
    box-shadow: 2px 2px 15px 10px rgba(151, 225, 194, 1);
  }


  @media screen and (max-width: 600px) {
    .playerAv {
      height: 80px;
      width:120px;
      display: flex;
      justify-content: center;
      padding-bottom: 0.4rem;
    }
  }

  @media screen and (max-width: 400px) {
    .playerAv {
      height: 90px;
      width: 120px;
      padding-bottom: 0.4rem;
    }

    .aboutPlayer {
      grid-column: 1 / -1;
    }
  }

  img { 
    aspect-ratio:1/1;
    border-radius: 50%; 
    object-fit: cover;
  }

  .playerData {
    padding-top: 1.5rem;
    grid-column: 1 / -1;
    display: flex; 
    flex-direction: column; 
    gap: 1.1rem;
  }

  input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
  }

`