import React from 'react';


export class Objects {

}


export class Context {
    static HostInfo =  React.createContext();
    static Range = React.createContext();
    static playerIds = React.createContext(); 
}


