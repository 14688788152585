import { useState, useContext, useEffect } from 'react';
import { RangeBoxWhole } from '../../../customComponent/RangeBox';
import styled from 'styled-components';
import { Constants, CODE_STATUS } from '../../../../utils/Constants';
import Register from '../../user/register/Register';
import {
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from '@mui/material';
import { TextField } from '@material-ui/core';
import { Context } from '../../user/AuthState';
import { postRequest } from '../../../../utils/axiosClient';
import { withStyles } from '@material-ui/core/styles';
import { accessByEmployee } from '../../../../utils/access';
import RegisteredUser from '../../user/register/RegisteredUser';
import { PageState } from '../../../../pageState';
import { saveScore, GAMES } from '../../../../utils/Constants';
import { ScoreError } from '../../../../utils/Constants';
import { TimeField } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getKiosk } from '../../../../utils/config';

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.9)', // (default alpha is 0.38)
    },
  },
})(TextField);

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: 'black',
  },
});

const Designs = {
  BtnDesign: {
    color: 'white',
    backgroundColor: 'black',
    padding: '0.4rem 2.5rem',
    borderRadius: '12px',
  },
};

const PlayerRegister = ({
  branchId,
  employeeId,
  leaderboardView,
  setLeaderboardView,
  filters,
}) => {
  const [rangeArray, setRangeArray] = useState(Constants.RANGE_ARRAY);
  const [tabValue, setTabValue] = useState(0);
  const range = useContext(Context.Range);
  const [kiosk, setKiosk] = useState();
  const [codeStatus, setCodeStatus] = useState(CODE_STATUS.PUBLISH);
  const [score, setScore] = useState({ scoreDetails: [] });
  const page = useContext(PageState.pageState);
  const [gameId, setGameId] = useState(null);
  const { pageState, setPageState } = page;
  const [currentPageState, setCurrentPageState] = useState('unknown');



  const tabClick = (e, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    if (leaderboardView) return;
    let token = localStorage.getItem('empToken');
    if (!token) {
      window.location = '/emplogin?branchid=' + branchId;
    }
    accessByEmployee({
      token: token,
      type: 'emp',
      success: () => {},
      failure: () => {
        window.location = '/emplogin?branchid=' + branchId;
      },
    });
    let empId = localStorage.getItem('empId');
    let gameId = localStorage.getItem('gameId');
    const setGetKioskAccept = (response) => {
      if (response.status === 200) {
        const index = response.data.message.findIndex(
          (el) => el.gameId === gameId
        );
        setCurrentPageState('loaded');
        if (index >= 0) {
          setKiosk(response.data.message[index]);
          setGameId(gameId);
          setScore({ gameId: gameId, employeeId: empId, scoreDetails: [] });
        } else {
          setCurrentPageState('error');
        }
      } else {
        setCurrentPageState('error');
      }
    };
    const setGetKioskReject = () => {
      setCurrentPageState('error');
    };
    setCurrentPageState('loading');
    getKiosk('/kiosk', setGetKioskAccept, setGetKioskReject);
  }, []);

  const checkFields = () => {
    let mappedObj = Object.keys(score).filter(
      (el) => !isNaN(el) && score[el].score
    );
    return mappedObj.length != range.rangeIndex;
  };

  if (currentPageState === 'loading') return <span>loading...</span>;

  return (
    <PlayerRegisterStyle>
      <div
        className={leaderboardView ? 'hidden' : 'PlayerRegister'}
        style={{ overflow: 'hidden' }}
      >
        <div className={'range'}>
          <RangeBoxWhole
            setRangeArray={setRangeArray}
            rangeArray={rangeArray}
          />
        </div>

        <div className="PlayerInfoContainer">
          <Tabs
            TabIndicatorProps={{
              style: { background: 'rgba(249, 173, 73, 1)' },
            }}
            variant="fullWidth"
            value={tabValue}
            className="tabs"
            textColor="black"
          >
            <Tab
              label="Existing User"
              value={0}
              className="tab"
              onClick={(e) => tabClick(e, 0)}
              sx={{ width: '50%', color: 'black' }}
            />
            <Tab
              label="Get Registered"
              value={1}
              className="tab"
              onClick={(e) => tabClick(e, 1)}
              sx={{ width: '50%', color: 'black' }}
            />
          </Tabs>

          <div
            className={tabValue != 0 || range.rangeIndex != 1 ? 'hidden' : null}
          >
            <RegisteredUser
              branchId={branchId}
              empPage={true}
              score={score}
              setScore={setScore}
            />
          </div>

          <div
            className={
              tabValue == 0 && range.rangeIndex > 1 ? 'GetPlayerData' : 'hidden'
            }
            style={{ overflowX: 'scroll' }}
          >
            <GetPlayerData
              gameId={gameId}
              kiosk={kiosk}
              rangeIndex={range.rangeIndex}
              branchId={branchId}
              score={score}
              setScore={setScore}
              setCodeStatus={setCodeStatus}
            />
            <Button
              onClick={() =>
                saveScore(
                  score,
                  pageState,
                  setPageState,
                  false,
                  false,
                  false,
                  filters
                )
              }
              className="btn"
              variant="contained"
              disabled={checkFields()}
              disabledRipple
              sx={Designs.BtnDesign}
            >
              {codeStatus === CODE_STATUS.SHOW ? 'Show Data' : 'Publish'}
            </Button>
          </div>

          <div className={tabValue === 1 ? null : 'hidden'}>
            <Register
              empPage={true}
              score={score}
              setScore={setScore}
              branchId={branchId}
            />
          </div>
        </div>
      </div>
    </PlayerRegisterStyle>
  );
};

const GetPlayerData = ({
  rangeIndex,
  score,
  setScore,
  setCodeStatus,
  gameId,
  kiosk,
}) => {
  const [gotCode, setGotCode] = useState({});
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    setActiveIndex(-1);
  }, []);

  const getPlayerDataFromCode = async (e, code, i) => {
    try {
      let response = await postRequest({
        endpoint: '/auth/getplayercode',
        data: { code: code },
        dispatch: null,
        controller: new AbortController(),
        headers: {},
      });
      if (response.status === 200) {
        let tempData = { [i]: { codeValue: code } };
        for (const el in response.msg) {
          tempData[i][el] = response.msg[el];
        }
        setScore({ ...score, [i]: tempData[i] });
        setGotCode({ ...gotCode, [i]: 'green' });
      } else {
        setGotCode({ ...gotCode, [i]: 'red' });
        delete score[i];
        setScore({ ...score });
      }
    } catch (e) {
      setGotCode({ ...gotCode, [i]: 'red' });
      delete score[i];
      setScore({ ...score });
    }
  };

  useEffect(() => {
    setScore({
      gameId: score.gameId,
      employeeId: score.employeeId,
      scoreDetals: [],
    });
    setGotCode({});
  }, [rangeIndex]);

  const handleInformationChange = async (e, i, type = false) => {
    if (type === 'code') {
      setScore({
        ...score,
        [i]: { ...score[i], [e.target.name]: e.target.value },
      });
      if (e.target.value.length === 4) {
        await getPlayerDataFromCode(e, e.target.value, i);
      }
    } else {
      setScore({
        ...score,
        [i]: { ...score[i], [e.target.name]: e.target.value },
      });
    }
  };

  return (
    <TableContainer sx={{ paddingTop: '1rem', minWidth: '600px' }}>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: 'rgba(249, 173, 73, 0.75)' }}>
            <TableCell size="small" align="center" width="70px">
              Code
            </TableCell>
            <TableCell size="small" align="center" width="50px"></TableCell>
            <TableCell align="center" size="small" width="200px">
              Name
            </TableCell>
            <TableCell align="center" size="small" width="100px">
              Gender
            </TableCell>
            <TableCell align="center" size="small" width="200px">
              Score
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {[...Array(rangeIndex)].map((element, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  <TextField
                    size="small"
                    variant="filled"
                    name="codeValue"
                    id={i}
                    InputProps={{ disableUnderline: true }}
                    disabled={gotCode[i] === 'green'}
                    style={
                      gotCode[i] === 'green'
                        ? { border: '2px solid green' }
                        : gotCode[i] === 'red'
                        ? { border: '2px solid red' }
                        : null
                    }
                    value={
                      score[i] && score[i].codeValue ? score[i].codeValue : ''
                    }
                    onChange={async (e) =>
                      handleInformationChange(e, i, 'code')
                    }
                  />
                </TableCell>

                <TableCell>
                  {score[i] && score[i].avatar ? (
                    <img src={score[i].avatar} />
                  ) : (
                    ''
                  )}{' '}
                </TableCell>

                <TableCell>
                  <DarkerDisabledTextField
                    size="medium"
                    variant="outlined"
                    name="name"
                    sx={{ paddingLeft: '1rem', color: 'red' }}
                    disabled={true}
                    fullWidth
                    value={score[i] && score[i].name ? score[i].name : ''}
                    onChange={(e) => handleInformationChange(e, i)}
                  />
                </TableCell>

                <TableCell>
                  <DarkerDisabledTextField
                    size="medium"
                    variant="outlined"
                    name="gender"
                    disabled={true}
                    value={score[i] && score[i].gender ? score[i].gender : ''}
                    onChange={(e) => handleInformationChange(e, i)}
                  />
                </TableCell>

                <TableCell>
                  {kiosk && kiosk.scoreType === 'time' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        style={{ width: '100%' }}
                        error={
                          score[i] && score[i].score
                            ? ScoreError(score[i].score)
                            : false
                        }
                        onInput={(e) => {
                          setScore({
                            ...score,
                            [i]: { ...score[i], score: e.target.value },
                          });
                        }}
                        format="hh:mm:ss"
                        size="small"
                      />
                    </LocalizationProvider>
                  ) : (
                    <TextField
                      type={'number'}
                      name="score"
                      variant="outlined"
                      size="medium"
                      placeholder="score"
                      fullWidth
                      inputProps={{ steps: '1' }}
                      error={
                        score[i] && score[i].score
                          ? ScoreError(kiosk.scoreType, score[i].score)
                          : false
                      }
                      value={
                        score[i] && score[i].score
                          ? score[i].score
                          : kiosk && kiosk.scoreType === 'time'
                          ? '00:00:00'
                          : ''
                      }
                      onChange={(e) => handleInformationChange(e, i)}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerRegister;

const PlayerRegisterStyle = styled.div`
  .PlayerRegister {
    display: grid;
    grid-gap: 1.4rem 0rem;
    grid-template-columns: repeat(8, 1fr);
    text-align: center;
  }

  .range {
    grid-column: 2 / 8;
    padding-top: 0.5rem;
  }

  .PlayerInfoContainer {
    grid-column: 1 / -1;
  }

  .PlayerInfoContainer .tabs {
    width: 100%;
  }

  .btn:hover {
    background-color: black;
  }

  input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }

  img {
    width: 50px;
    border-radius: 50%;
  }

  & td {
    border-bottom: none;
  }

  &.Mui-selected {
    color: black !important;
  }

  .hidden {
    display: none;
  }

  &.Mui-selected {
    color: black !important;
  }

  .GetPlayerData {
    height: 350px;
  }

  @media screen and (max-width: 600px) {
    .tabs {
      font-size: 0.6rem;
    }

    .css-1uloc3o-MuiButtonBase-root-MuiTab-root {
      font-size: 0.75rem;
    }

    .css-1uloc3o-MuiButtonBase-root-MuiTab-root {
      font-size: 0.75rem;
    }
  }
`;
